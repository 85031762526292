import React, { useEffect, useState } from 'react';
import { default as pdfImage } from "../assets/PDF Icon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const SideDrawer = ({ chatsFetched, convoId, selectedMessageIndex, isOpen, toggleDrawer, selectedSearchFiles, handleSearchFileID, selectedTab }) => {
  const [messageFiles, setMessageFiles] = useState([]);
  const [convoType, setconvoType] = useState();
  const [selectedIndex, setSelectedIndex] = useState(selectedMessageIndex);
  const [filteredMessageFiles, setfilteredMessageFiles] = useState([]);
  const [filteredFiles, setfilteredFiles] = useState([]);

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  useEffect(() => {
    if(chatsFetched && selectedIndex !== null){
      for(const chat of chatsFetched) {
          if(chat?.id === convoId){
              setMessageFiles(chat?.conversation_history[selectedIndex][3]);
          }
      }
    }
  }, [selectedTab])
  
  useEffect(() => 
    {
    if(convoId !== 'NEW'){
      setSelectedIndex(selectedMessageIndex)
      const timer = setTimeout(() => {
        if(chatsFetched && selectedMessageIndex !== null){
          for (const chat of chatsFetched) {
            if (chat?.id === convoId) {
              setMessageFiles(chat?.conversation_history[selectedMessageIndex][3]);
            }
          }
        }
      }, 500);
  
      return () => clearTimeout(timer);
    } else{
      setMessageFiles([]);
    }
  }, [selectedMessageIndex, convoId]);
  
  useEffect(() => {
    setMessageFiles(selectedSearchFiles?.items);
    setconvoType(selectedSearchFiles?.type);
    if(selectedSearchFiles?.items?.length === 0){
        handleSearchFileID(0)
    }
  }, [selectedSearchFiles])

  const handleOpenInNewTab = (url) => {
    window.open(url, '_blank');
  };

  const getSearchFileID = (id) =>{
    handleSearchFileID(id)
  }
  
  const filterSimilarFiles = (fileArray) => {
    const sortedArray = [];
  
    if(fileArray){
      fileArray.forEach((file) => {
        const isExactMatch = sortedArray.some(
          (existingFile) => file.name.toLowerCase() === existingFile.name.toLowerCase()
        );
    
        if (!isExactMatch) {
          sortedArray.push(file);
        }
      });
    }
  
    return sortedArray;
  };
  
  const createFilteredFileMapping = (filteredFiles, messageFiles) => {
    return filteredFiles.map((filteredFile) => {
      const matchingFiles = messageFiles.filter(
        (messageFile) => messageFile.name === filteredFile.name
      );
  
      return {
        name: filteredFile.name,
        files: matchingFiles,
      };
    });
  };

  useEffect(() => {
    const getfilteredFiles = filterSimilarFiles(messageFiles);
    setfilteredFiles(getfilteredFiles);
    const result = createFilteredFileMapping(getfilteredFiles, messageFiles);
    setfilteredMessageFiles(result)
  }, [messageFiles])

  return (
    <div style={{ zIndex: isOpen ? '2' : '1' }} className={`side-drawer ${isOpen ? 'open' : ''}`}>
      <button style={{ zIndex: isOpen ? '2' : '1' }} className={`toggle-button ${isOpen ? 'isOpen' : ''}`} onClick={toggleDrawer}> 
        <p style={{paddingRight: '1rem'}}>
            {filteredFiles?.length > 0 && (
            <span className='count-badge'>{filteredFiles?.length}</span>
            )}
            {selectedTab === 'Search' ? 'Selected Files' : 'Source Files'}
        </p>
        <div style={{position: 'relative', margin: 'auto 0'}}><p className={`arrow ${isOpen ? 'isOpen' : ''}`}></p></div>
      </button>
      {isOpen && (
        <div className="drawer-content">
          {Array.isArray(filteredFiles) && filteredFiles.length > 0 ? (
                selectedTab === 'Search' ? (
                    <p key="search-selected">Selected files:</p>
                ):(
                    <p key="search-source">Source files:</p>
                )
            ) : ( 
                selectedTab === 'Search' ? (
                    <p key="empty-selected">Selected files will appear here.</p>
                ):(
                    <p key="empty-source">Source files will appear here.</p>
                )
            )}
          <div className='src-shadow-box'>  
            {
              selectedTab === 'Search' ? (
                messageFiles?.map((file, index) => (
                    <div className='drawer-card'>
                        <img src={pdfImage} style={{ height: '20px', width: '15px' }} alt="More" />
                        <p style={{fontSize: '12px', cursor: 'pointer'}}  key={file?.id || `file-${index}`} onClick={() => handleOpenInNewTab(file["source"])}>{file.name}</p>
                        {selectedTab === 'Search' && (
                            <FontAwesomeIcon
                                style={{margin:'auto', position:'relative'}}
                                icon={faClose}
                                onClick={() => {
                                    getSearchFileID(file?.id)
                                }}
                            />
                        )}
                    </div>
                ))
              ) : (
              filteredMessageFiles?.length > 0 &&
                filteredMessageFiles.map((fileGroup, groupIndex) => (
                  <div
                    className="drawer-filename-dialog accordion bg-white rounded-md w-full max-w-lg mb-4 shadow-lg border-2 border-black"
                    key={groupIndex}
                  >
                    <div
                      className={`drawer-accordion-header flex items-center cursor-pointer drawer-dropdown search-headers ${
                        openDropdownIndex === groupIndex ? '' : 'accordian-radius'
                      }`}
                      onClick={() => toggleDropdown(groupIndex)}
                    >
                      <img src={pdfImage} style={{ height: '20px', width: '15px' }} alt="More" />
                      <p style={{fontSize: '12px', cursor: 'pointer', width: '85%', margin: '0'}} className="text-xl text-left text-primary">
                        {fileGroup.name}
                      </p>
                      <span className='count-badge' style={{ marginRight: '20px' }}>{fileGroup?.files?.length}</span>
                      <span className={`drawer-accordion-icon ${openDropdownIndex === groupIndex ? 'rotate-180' : ''}`}></span>
                    </div>
                    {openDropdownIndex === groupIndex && (
                      <div className="accordion-body">
                        <div className="text-container">
                          {fileGroup?.files?.length > 0 ? (
                            fileGroup.files.map((file, fileIndex) => (
                              <div onClick={() => handleOpenInNewTab(file.source)} key={fileIndex}>
                                  <p className="drawer-card" style={{ margin: '12px 0.25rem', cursor: 'pointer', color: 'black', fontSize: '10px' }}>
                                    Page {file.page}
                                  </p>
                              </div>
                            ))
                          ) : (
                            <p style={{ margin: '12px', paddingLeft: '14px' }}>No results found.</p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default SideDrawer;
